import { default as _91_46_46_46slug_93mUWE4p6xGcMeta } from "/builds/tln/tln-app/pages/[...slug].vue?macro=true";
import { default as account_45aanmakenhfELFlYlHkMeta } from "/builds/tln/tln-app/pages/account-aanmaken.vue?macro=true";
import { default as _91slug_93j5OYWMERcmMeta } from "/builds/tln/tln-app/pages/actueel/[slug].vue?macro=true";
import { default as indexbG2DQYEFPFMeta } from "/builds/tln/tln-app/pages/actueel/index.vue?macro=true";
import { default as _91id_93ha5ODYLClcMeta } from "/builds/tln/tln-app/pages/bijeenkomsten/[slug]/[id].vue?macro=true";
import { default as index93U3YyPOfJMeta } from "/builds/tln/tln-app/pages/bijeenkomsten/index.vue?macro=true";
import { default as _91slug_93thaSVIErdXMeta } from "/builds/tln/tln-app/pages/hetluktalleenmetlogistiek/[slug].vue?macro=true";
import { default as index8wfNuccdGDMeta } from "/builds/tln/tln-app/pages/hetluktalleenmetlogistiek/index.vue?macro=true";
import { default as indexbQwXJe46q4Meta } from "/builds/tln/tln-app/pages/index.vue?macro=true";
import { default as _91slug_93jKvHT1qTtpMeta } from "/builds/tln/tln-app/pages/kennis/[thema]/[slug].vue?macro=true";
import { default as indexLwkOGibmGfMeta } from "/builds/tln/tln-app/pages/kennis/[thema]/index.vue?macro=true";
import { default as indexpVXDAmVOS9Meta } from "/builds/tln/tln-app/pages/kennis/index.vue?macro=true";
import { default as _91slug_93W6vJsDcJ3DMeta } from "/builds/tln/tln-app/pages/ledenvoordeel/[slug].vue?macro=true";
import { default as indexfUpgqxW4VXMeta } from "/builds/tln/tln-app/pages/ledenvoordeel/index.vue?macro=true";
import { default as dossiers8McGcSUhkiMeta } from "/builds/tln/tln-app/pages/mijn-tln/dossiers.vue?macro=true";
import { default as loginXGk63WhmgEMeta } from "/builds/tln/tln-app/pages/mijn-tln/login.vue?macro=true";
import { default as logoutIDh6srtNIaMeta } from "/builds/tln/tln-app/pages/mijn-tln/logout.vue?macro=true";
import { default as _91id_93jnxTeo9NsGMeta } from "/builds/tln/tln-app/pages/mijn-tln/organisaties/[id].vue?macro=true";
import { default as indexaf88AfnxmKMeta } from "/builds/tln/tln-app/pages/mijn-tln/organisaties/index.vue?macro=true";
import { default as profielIS4cD63e32Meta } from "/builds/tln/tln-app/pages/mijn-tln/profiel.vue?macro=true";
import { default as _91slug_93bQpK61N1WBMeta } from "/builds/tln/tln-app/pages/opleidingen/[slug].vue?macro=true";
import { default as indexCh0cD9eVPxMeta } from "/builds/tln/tln-app/pages/opleidingen/index.vue?macro=true";
import { default as _91content_45type_934Xb0vkx04VMeta } from "/builds/tln/tln-app/pages/zoeken/[content-type].vue?macro=true";
import { default as indexEKX19EXwkfMeta } from "/builds/tln/tln-app/pages/zoeken/index.vue?macro=true";
import { default as component_45stub0p0IQw0gMvMeta } from "/builds/tln/tln-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@8.57.1_ioredis@5.4.1_less@4.2.1__p5he6rk7yw2nhzforavvepad4e/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub0p0IQw0gMv } from "/builds/tln/tln-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@8.57.1_ioredis@5.4.1_less@4.2.1__p5he6rk7yw2nhzforavvepad4e/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/builds/tln/tln-app/pages/[...slug].vue")
  },
  {
    name: "account-aanmaken",
    path: "/account-aanmaken",
    component: () => import("/builds/tln/tln-app/pages/account-aanmaken.vue")
  },
  {
    name: "actueel-slug",
    path: "/actueel/:slug()",
    component: () => import("/builds/tln/tln-app/pages/actueel/[slug].vue")
  },
  {
    name: "actueel",
    path: "/actueel",
    component: () => import("/builds/tln/tln-app/pages/actueel/index.vue")
  },
  {
    name: "bijeenkomsten-slug-id",
    path: "/bijeenkomsten/:slug()/:id()",
    component: () => import("/builds/tln/tln-app/pages/bijeenkomsten/[slug]/[id].vue")
  },
  {
    name: "bijeenkomsten",
    path: "/bijeenkomsten",
    component: () => import("/builds/tln/tln-app/pages/bijeenkomsten/index.vue")
  },
  {
    name: "hetluktalleenmetlogistiek-slug",
    path: "/hetluktalleenmetlogistiek/:slug()",
    meta: _91slug_93thaSVIErdXMeta || {},
    component: () => import("/builds/tln/tln-app/pages/hetluktalleenmetlogistiek/[slug].vue")
  },
  {
    name: "hetluktalleenmetlogistiek",
    path: "/hetluktalleenmetlogistiek",
    meta: index8wfNuccdGDMeta || {},
    component: () => import("/builds/tln/tln-app/pages/hetluktalleenmetlogistiek/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/tln/tln-app/pages/index.vue")
  },
  {
    name: "kennis-thema-slug",
    path: "/kennis/:thema()/:slug()",
    component: () => import("/builds/tln/tln-app/pages/kennis/[thema]/[slug].vue")
  },
  {
    name: "kennis-thema",
    path: "/kennis/:thema()",
    component: () => import("/builds/tln/tln-app/pages/kennis/[thema]/index.vue")
  },
  {
    name: "kennis",
    path: "/kennis",
    component: () => import("/builds/tln/tln-app/pages/kennis/index.vue")
  },
  {
    name: "ledenvoordeel-slug",
    path: "/ledenvoordeel/:slug()",
    component: () => import("/builds/tln/tln-app/pages/ledenvoordeel/[slug].vue")
  },
  {
    name: "ledenvoordeel",
    path: "/ledenvoordeel",
    component: () => import("/builds/tln/tln-app/pages/ledenvoordeel/index.vue")
  },
  {
    name: "mijn-tln.dossiers",
    path: "/mijn-tln/dossiers",
    meta: dossiers8McGcSUhkiMeta || {},
    component: () => import("/builds/tln/tln-app/pages/mijn-tln/dossiers.vue")
  },
  {
    name: "mijn-tln-login",
    path: "/mijn-tln/login",
    meta: loginXGk63WhmgEMeta || {},
    component: () => import("/builds/tln/tln-app/pages/mijn-tln/login.vue")
  },
  {
    name: "mijn-tln-logout",
    path: "/mijn-tln/logout",
    meta: logoutIDh6srtNIaMeta || {},
    component: () => import("/builds/tln/tln-app/pages/mijn-tln/logout.vue")
  },
  {
    name: "mijn-tln.organisatie",
    path: "/mijn-tln/organisaties/:id()",
    meta: _91id_93jnxTeo9NsGMeta || {},
    component: () => import("/builds/tln/tln-app/pages/mijn-tln/organisaties/[id].vue")
  },
  {
    name: "mijn-tln.organisaties",
    path: "/mijn-tln/organisaties",
    meta: indexaf88AfnxmKMeta || {},
    component: () => import("/builds/tln/tln-app/pages/mijn-tln/organisaties/index.vue")
  },
  {
    name: "mijn-tln.profiel",
    path: "/mijn-tln/profiel",
    meta: profielIS4cD63e32Meta || {},
    component: () => import("/builds/tln/tln-app/pages/mijn-tln/profiel.vue")
  },
  {
    name: "opleidingen-slug",
    path: "/opleidingen/:slug()",
    component: () => import("/builds/tln/tln-app/pages/opleidingen/[slug].vue")
  },
  {
    name: "opleidingen",
    path: "/opleidingen",
    component: () => import("/builds/tln/tln-app/pages/opleidingen/index.vue")
  },
  {
    name: "zoeken-contenttype",
    path: "/zoeken/:contenttype()",
    component: () => import("/builds/tln/tln-app/pages/zoeken/[content-type].vue")
  },
  {
    name: "zoeken",
    path: "/zoeken",
    component: () => import("/builds/tln/tln-app/pages/zoeken/index.vue")
  },
  {
    name: component_45stub0p0IQw0gMvMeta?.name,
    path: "/mijn-tln",
    component: component_45stub0p0IQw0gMv
  }
]