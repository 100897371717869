import { defineStore } from 'pinia';
import UserModel, { type User } from '~/utils/UserModel';
import {
    COOKIE_KEY_INITIAL_PAGE_PATH,
    COOKIE_KEY_AUTH_ROLE
} from '~/constants/cookies';
import { userApi } from '~/utils/userApi';

export const useAuthStore = defineStore('auth-store', {
    state: () => ({
        user: null as User | null,
        jwtToken: null as null | string
    }),

    getters: {
        userModel(): object|null {
            if (!this.user) {
                return null;
            }

            return new UserModel(this.user);
        },

        initialPage(): string|undefined {
            const cookie = useCookie(COOKIE_KEY_INITIAL_PAGE_PATH, {
                sameSite: 'strict'
            });

            return cookie.value || undefined;
        },

        loggedIn(): boolean {
            return !!this.user;
        },

        async getJwtToken(): Promise<string|null> {
            if (this.jwtToken) {
                return this.jwtToken;
            }

            const cookie = await useJwtToken();

            return cookie.value ?? null;
        }
    },

    actions: {
        async fetchUser() {
            const response = await userApi('user');

            this.user = response;

            return this.user;
        },

        async refreshJwtToken() {
            return await useNuxtApp().runWithContext(async() => {
                return await userApi('auth/refresh', {
                    method: 'POST'
                });
            });
        },

        async deleteUserSession() {
            await useNuxtApp().runWithContext(async() => {
                this.unsetUserRoleGTM();

                await userApi('auth/logout', { method: 'POST' });

                const cookie = await useJwtToken();

                cookie.value = null;
            });
        },

        setInitialPageCookie(path: string) {
            const cookie = useCookie(COOKIE_KEY_INITIAL_PAGE_PATH, {
                sameSite: 'strict'
            });

            cookie.value = path;

            refreshCookie(COOKIE_KEY_INITIAL_PAGE_PATH);
        },

        // Add user role to cookies for GTM
        setUserRoleGTM(user: User) {
            useNuxtApp().runWithContext(() => {
                const contentPermissions = user.content_permissions;
                let userRole = null;
    
                if (contentPermissions.tln) {
                    userRole = 'tln_lid';
                }
    
                // Overwrite TLN role if user is also international member
                if (contentPermissions.tln_international) {
                    userRole = 'internationaal_lid';
                }
    
                const cookie = useCookie(COOKIE_KEY_AUTH_ROLE, {
                    sameSite: 'strict'
                });
    
                if (cookie.value !== userRole) {
                    cookie.value = userRole;
                }
            });
        },

        unsetUserRoleGTM() {
            const cookie = useCookie(COOKIE_KEY_AUTH_ROLE);

            cookie.value = null;
        },
    }
});
